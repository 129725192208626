import React from "react"
import DatenschutzContent from "../components/DatenschutzContent"
import Layout from "../components/Layout"
import Section from "../components/Section"

export interface DatenschutzerklaerungProps {}

const Datenschutzerklaerung: React.SFC<DatenschutzerklaerungProps> = () => {
  return (
    <Layout>
      <Section>
        <DatenschutzContent />
      </Section>
    </Layout>
  )
}

export default Datenschutzerklaerung
