import React from "react"
import { H1 } from "./text/H1"
import { H2 } from "./text/H2"
import { H3 } from "./text/H3"
import { H4 } from "./text/H4"
import { OutsideLink } from "./text/OutsideLink"
import { Paragraph } from "./text/Paragraph"

export interface DatenschutzContentProps {}

const DatenschutzContent: React.SFC<DatenschutzContentProps> = () => {
  return (
    <>
      <H1 text="Datenschutzerklärung" />
      <H2 text="1. Datenschutz auf einen Blick" />
      <H3 text="Allgemeine Hinweise" />
      <Paragraph>
        Die folgenden Hinweise geben einen Überblick darüber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können.
      </Paragraph>
      <H3 text="Datenerfassung auf unserer Website" />
      <H4 text="Wer ist verantwortlich für die Datenerfassung auf dieser Website?" />
      <Paragraph>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </Paragraph>
      <H4 text="Gibt es einen Datenschutzbeauftragten?" />
      <Paragraph>
        Ja, wir haben einen Datenschutzbeauftragten. Diesen können Sie für alle
        Datenschutzbelange unter datenschutz@diepsychotherapieapp.de, +49 6021
        624384 erreichen.
      </Paragraph>
      <H4 text="Wie erfassen wir Ihre Daten?" />
      <Paragraph>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </Paragraph>
      <Paragraph>
        Andere Daten werden automatisch beim Besuch der Website durch unsere
        IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
        Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
        Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
        betreten.
      </Paragraph>
      <H4 text="Wofür nutzen wir Ihre Daten?" />
      <Paragraph>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </Paragraph>
      <Paragraph>
        Haben Sie sich zum Newsletter angemeldet, verwenden wir die Daten, um
        Sie über Neuigkeiten per Mail zu informieren.
      </Paragraph>
      <H4 text="Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?" />
      <Paragraph>
        Um Ihnen unsere Internetseite und die damit verbundenen Dienstleistungen
        anbieten zu können, verarbeiten wir personenbezogene Daten auf Basis
        einer Interessenabwägung (Art. 6 Abs. 1 lit. f, DSGVO) oder zur
        Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 lit. c, DSGVO).
        Die Newsletterdaten verarbeiten wir auf Basis Ihrer Einwilligung (Art. 6
        Abs. 1 lit. a, DSGVO)
      </Paragraph>
      <Paragraph>
        Wir werden im Zusammenhang mit der jeweiligen Verarbeitung auf die
        entsprechenden Begrifflichkeiten Bezug nehmen. Wenn wir Daten auf Basis
        einer Interessenabwägung verarbeiten, haben Sie als Betroffene/r das
        Recht, unter Berücksichtigung der Vorgaben von Art. 21 DSGVO der
        Verarbeitung der personenbezogenen Daten zu widersprechen.
      </Paragraph>
      <Paragraph>
        Wenn wir Daten auf Basis Ihrer Einwilligung verarbeiten, haben Sie als
        Betroffene/r das Recht, die Einwilligung mit Wirkung für die Zukunft zu
        widerrufen.
      </Paragraph>
      <Paragraph>
        Wir werden in den nachfolgenden Abschnitten auf die jeweilige
        Rechtsgrundlage zur Datenverarbeitung verweisen.
      </Paragraph>
      <H3 text="Empfänger / Weitergabe von Daten" />
      <Paragraph>
        Daten, die Sie uns gegenüber angeben, werden nicht an Dritte für
        Werbezwecke weitergegeben.
      </Paragraph>
      <Paragraph>
        Wir setzen ggf. Dienstleister für den Betrieb dieser Internetseiten oder
        für weitere Dienstleistungen von uns ein. In diesem Fall erlangt ein
        Dienstleister Kenntnis von personenbezogenen Daten. Wir wählen unsere
        Dienstleister sorgfältig – insbesondere im Hinblick auf Datenschutz und
        Datensicherheit – aus und treffen alle datenschutzrechtlich
        erforderlichen Maßnahmen für eine zulässige Datenverarbeitung.
      </Paragraph>
      <H4 text="Welche Rechte haben Sie bezüglich Ihrer Daten?" />
      <Paragraph>
        Sie haben das Recht unentgeltlich Auskunft über Ihre gespeicherten
        personenbezogenen Daten zu erhalten. Sie haben außerdem das Recht, die
        Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen, soweit
        Ihnen das gesetzlich zusteht. Hierzu sowie zu weiteren Fragen zum Thema
        Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse oder über unseren Datenschutzbeauftragten an uns wenden.
      </Paragraph>
      <Paragraph>
        Bei der Ausübung Ihrer Rechte bitten wir um Verständnis dafür, dass wir
        ggf. einen Identitätsausweis verlangen.
      </Paragraph>
      <H3 text="Welche Daten erfassen wir?" />
      <H4 text="Zugriffsdaten" />
      <Paragraph>
        Damit die Seiten in Ihrem Browser dargestellt werden können, muss die
        IP-Adresse des von Ihnen verwendeten Endgeräts verarbeitet werden. Hinzu
        kommen weitere Information über den Browser Ihres Endgeräts. Wir sind
        datenschutzrechtlich verpflichtet, auch die Vertraulichkeit und
        Integrität der mit unseren IT-Systemen verarbeiteten personenbezogenen
        Daten zu gewährleisten. Auch hierfür benötigen wir Daten.
      </Paragraph>
      <Paragraph>
        Auf Basis einer Interessenabwägung werden nachfolgende Daten
        protokolliert:
        <ul className="list-disc list-inside">
          <li>IP-Adresse des aufrufenden Rechners</li>
          <li>Betriebssystem des aufrufenden Rechners</li>
          <li>Browser Version des aufrufenden Rechners</li>
          <li>Name der abgerufenen Datei</li>
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>übertragene Datenmenge</li>
          <li>Verweisende URL</li>
        </ul>
      </Paragraph>
      <Paragraph>
        Die IP-Adresse wird nach spätestens 30 Tagen von allen Systemen, die im
        Zusammenhang mit dem Betrieb dieser Internetseiten verwendet werden,
        gelöscht. Einen Personenbezug können wir aus den verbleibenden Daten
        dann nicht mehr herstellen.
      </Paragraph>
      <Paragraph>
        Die Daten werden ferner auch verwendet, um Fehler auf den Internetseiten
        ermitteln und beheben zu können.
      </Paragraph>
      <H4 text="Cookies/Chat" />
      <Paragraph>
        Wir nutzen eine Chat Software des Unternehmens Userlike UG
        (haftungsbeschränkt) <OutsideLink href="http://www.userlike.com/" />,
        Probsteigasse 44-46, 50670 Köln.
      </Paragraph>
      <Paragraph>
        Zur Nutzung des Chats sind zwei Cookies notwendig. Cookies sind kleine
        Textinformationen, die über ihren Browser auf Ihrem Endgerät gespeichert
        werden. Einer der Cookies ist ein sogenannter Session-Cookie, der
        unmittelbar nach Beendigung des Besuchs der Internetseiten und
        Beendigung der Browser-Session automatisch von Ihrem Browser gelöscht
        wird. Der zweite Cookie ist ein sogenannter persistenter Cookie, der 1
        Jahr auf Ihrem Rechner verbleibt. Durch diese Cookie können wir Sie bei
        einem Rückkehr auf unsere Webseite identifiziert.
      </Paragraph>
      <Paragraph>
        Sie haben die Möglichkeit, das Setzen von Cookies durch entsprechende
        Einstellungen in Ihrem Browser zu unterbinden. Wir weisen jedoch darauf
        hin, dass die Nutzung des Chats dann ggf. nur eingeschränkt möglich ist.
        Durch Cookies werden keine Programme oder sonstige Applikationen auf
        Ihrem Rechner installiert oder gestartet.
      </Paragraph>
      <Paragraph>
        Sie können den Chat wie ein Kontaktformular nutzen, um nahezu in
        Echtzeit mit unseren Mitarbeitern zu chatten. Beim Start des Chats
        können folgende personenbezogene Daten erhoben werden:
        <ul className="list-disc list-inside">
          <li>Datum und Uhrzeit des Aufrufs,</li>
          <li>Browsertyp/ -version,</li>
          <li>IP-Adresse,</li>
          <li>verwendetes Betriebssystem,</li>
          <li>URL der zuvor besuchten Webseite,</li>
          <li>Menge der gesendeten Daten.</li>
          <li>Und wenn angegeben: Vorname, Name, und E-Mail Adresse.</li>
        </ul>
      </Paragraph>
      <Paragraph>
        Je nach Gesprächsverlauf mit unseren Mitarbeitern, können im Chat
        weitere personenbezogene Daten anfallen, die durch Sie angegeben werden.
        Die Art dieser Daten hängt stark von Ihrer Anfrage ab oder dem Problem,
        welches Sie uns schildern. Die Verarbeitung all dieser Daten dient dazu,
        Ihnen eine schnelle und effiziente Kontaktmöglichkeit zur Verfügung zu
        stellen und somit unseren Kundenservice zu verbessern. Durch das
        Aufrufen unserer Webseite, wird das Chat-Widget in Form einer
        JavaScript-Datei von AWS Cloudfront geladen. Das Chat-Widget stellt
        technisch den Quellcode dar, der auf Ihrem Computer ausgeführt wird und
        den Chat ermöglicht.
      </Paragraph>
      <Paragraph>
        Darüber hinaus speicher wir den Verlauf der Chats für die Dauer von
        maximal 6 Tagen. Dies dient dem Zweck, Ihnen unter Umständen
        umfangreiche Ausführungen zur Historie Ihrer Anfrage zu ersparen sowie
        zur beständigen Qualitätskontrolle unseres Chat-Angebots. Sofern Sie
        dies nicht wünschen, können Sie uns dies gerne mitteilen. Gespeicherte
        Chats werden dann unverzüglich von uns gelöscht.
      </Paragraph>
      <Paragraph>
        Die Speicherung der Chatdaten dient auch dem Zweck, die Sicherheit
        unserer informationstechnischen Systeme zu gewährleisten.
      </Paragraph>
      <Paragraph>
        Der Einsatz von Cookies und die Speicherung der Chat-Daten erfolgt auf
        Basis einer Interessenabwägung. Unser Interesse ist die Bereitstellung
        einer Chat Funktion.
      </Paragraph>
      <Paragraph>
        Weitere Informationen zum Umgang mit Ihren Daten finden Sie in den
        Datenschutzbestimmungen der Userlike UG(haftungsbeschränkt),
        <OutsideLink href="http://www.userlike.com/terms#privacy-policy" />.
      </Paragraph>
      <H4 text="Kontakt über Mail" />
      <Paragraph>
        Sie können mit uns Kontakt per Mail aufnehmen. Wir speichern dann aus
        einem berechtigten Interesse alle Daten der Mail, bis wir Ihre Anfrage
        bearbeitet haben. In dem Zusammenhang erhalten Mitarbeiter, Partner und
        ggf. Dritte Kenntnis von den Mailinhalten, die Sie übersendet haben.
      </Paragraph>
      <Paragraph>
        Maildaten werden 3 Jahre nach Bearbeitung des Anliegens gelöscht, es sei
        denn es sind gesetzlich andere Aufbewahrungsfristen vorgesehen. Dann
        gelten diese.
      </Paragraph>
      <H4 text="Fragebogen" />
      <Paragraph>
        Airtable ist ein Onlineservice, der Datenbank und Tabellenkalkulation
        verbindet. Wir Nutzen Airtable für den Fragebogen. Airtable kommt erst
        dann zur Anwendung, wenn wir einen Fragebogen versenden wird. Nähere
        Informationenunter <OutsideLink href="https://airtable.com/privacy" />.
      </Paragraph>
      <Paragraph>
        Die Daten verarbeiten wir auf Basis einer Einwilligung.{" "}
      </Paragraph>
      <Paragraph>Die Daten speichert Airtable in den USA.</Paragraph>
      <Paragraph>
        Die Fragebogendaten werden 3 Jahre nach Auswertung gelöscht.
      </Paragraph>
      <H4 text="Newsletter" />
      <Paragraph>
        Diese Website nutzt Mailjet für den Versand von Newslettern. Anbieter
        ist die Mailjet SAS, 13-13 bis, Rue de l’Aubrac – 75012 Paris,
        Frankreich. Mailjet ist ein Dienst, mit dem der Newsletterversand
        organisiert und analysiert werden kann. Die von Ihnen zwecks
        Newsletterbezug eingegebenen Daten (z.B. E-Mail-Adresse) werden auf den
        Servern von Mailjet in der EU gespeichert.
      </Paragraph>
      <Paragraph>
        Unsere mit Mailjet versandten Newsletter ermöglichen uns die Analyse des
        Verhaltens der Newsletterempfänger. Weitere Informationen zur
        Datenanalyse durch Mailjet-Newsletter erhalten Sie unter:{" "}
        <OutsideLink href="https://www.mailjet.de/funktion/tracking-tools/" />
      </Paragraph>
      <Paragraph>
        Mailjet ermöglicht uns auch, die Newsletter-Empfänger anhand
        verschiedener Kategorien zu unterteilen (“Segmentierung”). Dabei lassen
        sich die Newsletter-Empfänger nach den bei der Anmeldung angegebenen
        Daten unterteilen. Auf diese Weise lassen sich die Newsletter besser an
        die jeweiligen Zielgruppen anpassen. Ausführliche Informationen zu den
        Funktionen von Mailjet entnehmen Sie folgendem Link:{" "}
        <OutsideLink href="https://www.mailjet.de/funktion/" />
      </Paragraph>
      <Paragraph>
        Wenn Sie keine Analyse durch Mailjet wollen, müssen Sie den Newsletter
        abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen
        entsprechenden Link zur Verfügung. Des Weiteren können Sie den
        Newsletter auch direkt auf der Website abbestellen. Näheres entnehmen
        Sie bitte den Informationen zu “Sicherheit und Datenschutz” von Mailjet
        unter:{" "}
        <OutsideLink href="https://www.mailjet.de/sicherheit-datenschutz/" />{" "}
        und den Datenschutzbestimmungen von Mailjet unter:{" "}
        <OutsideLink href="https://www.mailjet.de/privacy-policy/" />
      </Paragraph>
      <Paragraph>
        Die Daten zum Newsletter verarbeiten wir auf Basis einer Einwilligung.
      </Paragraph>
      <Paragraph>
        Die Daten zum Erhalt des Newsletters werden gelöscht, wenn Sie den
        Newsletter abbestellen. Die Daten zum Verhalten der Newsletterempfänger
        werden 13 Monate gespeichert.{" "}
      </Paragraph>
      <H4 text="Webseite-Analysedaten" />
      <Paragraph>
        Wir verwenden zur Analyse des Nutzerverhaltens auf unserer Webseite
        „Matomo“. In Server-Log-Dateien erhebt und speichert der Provider der
        Website Matomo automatisch Informationen, die Ihr Browser an uns
        übermittelt. Diese Daten werden von dem Analysetool Matomo ausgewertet
        und aufbereitet. Die gespeicherten Informationen umfassen: die
        anonymisierte IP-Adresse, Besuchte Seiten auf unserer Domain, Datum und
        Uhrzeit der Serveranfrage, Browsertyp und Browserversion, Verwendetes
        Betriebssystem, Referrer URL, Hostname des zugreifenden Rechners.
      </Paragraph>
      <Paragraph>
        Zweck der Verarbeitung ist die Ermöglichung der Inanspruchnahme sowie
        die Verbesserung der Website. Es findet keine Zusammenführung dieser
        Daten mit anderen Datenquellen statt.
      </Paragraph>
      <Paragraph>
        Die Daten verarbeiten wir auf Basis einer Interessenabwägung.
      </Paragraph>
      <Paragraph>
        Die Daten von Matomo werden 3 Jahr nach Auswertung gelöscht.
      </Paragraph>
      <H2 text="2. Allgemeine Hinweise und Pflichtinformationen" />
      <H3 text="Datenverarbeitung außerhalb der Europäischen Union" />
      <Paragraph>
        Soweit personenbezogene Daten außerhalb der europäischen Union
        verarbeitet werden, können Sie dies den vorherigen Ausführungen
        entnehmen.
      </Paragraph>
      <H3 text="Ihre Rechte als Betroffene/r" />
      <Paragraph>
        Sie haben das Recht auf <strong>Auskunft</strong> über die Sie
        betreffenden personenbezogenen Daten. Sie können sich für eine Auskunft
        jederzeit an uns wenden.
      </Paragraph>
      <Paragraph>
        Bei einer Auskunftsanfrage, die nicht schriftlich erfolgt, bitten wir um
        Verständnis dafür, dass wir ggf. Nachweise von Ihnen verlangen, die
        belegen, dass Sie die Person sind, für die Sie sich ausgeben.
      </Paragraph>
      <Paragraph>
        Ferner haben Sie ein Recht auf Berichtigung oder Löschung oder auf
        Einschränkung der Verarbeitung, sowie auf Datenübertragung, soweit Ihnen
        dies gesetzlich zusteht
      </Paragraph>
      <Paragraph>
        Schließlich haben Sie ein <strong>Widerspruchsrecht</strong> gegen die
        Verarbeitung im Rahmen der gesetzlichen Vorgaben. Gleiches gilt für ein
        Recht auf Datenübertragbarkeit.
      </Paragraph>
      <H3 text="Löschung von Daten" />
      <Paragraph>
        Wir löschen personenbezogene Daten grundsätzlich dann, wenn kein
        Erfordernis für eine weitere Speicherung besteht. Ein Erfordernis kann
        insbesondere dann bestehen, wenn die Daten noch benötigt werden, um
        vertragliche Leistungen zur erfüllen, Gewährleistungs- und ggf.
        Garantieansprüche prüfen und gewähren oder abwehren zu können. Im Falle
        von gesetzlichen Aufbewahrungspflichten kommt eine Löschung erst nach
        Ablauf der jeweiligen Aufbewahrungspflicht in Betracht.
      </Paragraph>
      <H3 text="Beschwerderecht bei einer Aufsichtsbehörde" />
      <Paragraph>
        Sie haben das Recht, sich über die Verarbeitung personenbezogenen Daten
        durch uns bei einer <strong>Aufsichtsbehörde</strong> für den
        Datenschutz zu beschweren.
      </Paragraph>
      <H3 text="Änderung dieser Datenschutzhinweise" />
      <Paragraph>
        Wir überarbeiten diese Datenschutzhinweise bei Änderungen an dieser
        Internetseite oder bei sonstigen Anlässen, die dies erforderlich machen.
        Die jeweils aktuelle Fassung finden Sie stets auf dieser Internetseite
      </Paragraph>
      <Paragraph>
        Würzburg, 05.02.2021
        <br />
        Ort, Datum
      </Paragraph>
    </>
  )
}

export default DatenschutzContent
