import React from "react"

export interface H3Props {
  text: string
  className?: string
  mb?: number
}

export const H3: React.SFC<H3Props> = ({ text, className, mb }) => {
  return (
    <h3
      className={`md:text-2xl text-xl mb-${
        mb ? mb : 2
      } font-semibold font-heading ${className}`}
    >
      {text}
    </h3>
  )
}
