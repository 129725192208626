import React from "react"

export enum LinkType {
  mail,
  phone,
}

export interface OutsideLinkProps {
  href: string
  input?: JSX.Element | string
  type?: LinkType
}

export const OutsideLink: React.SFC<OutsideLinkProps> = ({
  href,
  input,
  type,
}) => {
  switch (type) {
    case LinkType.phone:
      return (
        <a
          href={`tel:${href}`}
          className="text-primary-600 hover:text-primary-800 no-underline"
        >
          {input ? input : href}
        </a>
      )
    case LinkType.mail:
      return (
        <a
          href={`mailto:${href}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-600 hover:text-primary-800 no-underline"
        >
          {input ? input : href}
        </a>
      )
    default:
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-600 hover:text-primary-800 no-underline"
        >
          {input ? input : href}
        </a>
      )
  }
}
