import React from "react"

export interface ParagraphProps {
  children:
    | string
    | JSX.Element
    | Element
    | (string | JSX.Element | Element | false)[]
  className?: string
  mb?: number
}

export const Paragraph: React.SFC<ParagraphProps> = ({
  children,
  className,
  mb,
}) => {
  return (
    <p className={`text-gray-600 mb-${mb !== undefined ? mb : 6} ${className}`}>
      {children}
    </p>
  )
}
