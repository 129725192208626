import React from "react"

export interface H4Props {
  text: string
  className?: string
  mb?: number
}

export const H4: React.SFC<H4Props> = ({ text, className, mb }) => {
  return (
    <h4
      className={`md:text-xl text-base mb-${
        mb ? mb : 2
      } font-semibold font-heading ${className}`}
    >
      {text}
    </h4>
  )
}
