import React from "react"

export interface H2Props {
  text: string
  className?: string
  mb?: number
}

export const H2: React.SFC<H2Props> = ({ text, className, mb }) => {
  return (
    <h2
      className={`${className} xl:text-4xl md:text-3xl text-2xl mb-${
        mb ? mb : 6
      } leading-tight font-semibold font-heading`}
    >
      {text}
    </h2>
  )
}
