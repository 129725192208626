import React, { ReactElement } from "react"

export interface SectionProps {
  children: (ReactElement | undefined)[] | ReactElement
  className?: string
  background?: boolean
  id?: string
}

const Section: React.SFC<SectionProps> = ({
  children,
  className,
  background,
  id,
}) => {
  return (
    <section
      className={`px-4 xl:py-36 py-24 ${
        background ? "bg-gray-100" : ""
      } ${className}`}
      id={id}
    >
      <div className="container mx-auto">{children}</div>
    </section>
  )
}

export default Section
