import React from "react"

export interface H1Props {
  text: string
  className?: string
}

export const H1: React.SFC<H1Props> = ({ text, className }) => {
  return (
    <h1
      className={`xl:text-5xl md:text-4xl text-3xl mb-6 leading-tight font-semibold font-heading ${className}`}
    >
      {text}
    </h1>
  )
}
